import Vue from 'vue'
import Vuex from 'vuex'
import AuthModule from './modules/authModule'
import {axiosbd} from "@/api/axios-bd"

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    // emailReg: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]{2,})$/,
    emailReg: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*\.[a-zA-Z]{2,}$/,
    emisionDocumento: [
      // {codigo: 0, text: 'Todos'},
      {codigo: 1 , text: 'Emitidos'},
      {codigo: 2 , text: 'Recibidos'},
    ],
    estadoDocumento: [
      {codigo: 0 , text: 'Procesando'},
      {codigo: 99 , text: 'Procesando'},
      {codigo: 98 , text: 'Error generando documento'},
      {codigo: 97 , text: 'Error en firma'},
      {codigo: 96 , text: 'Documento firmado'},
      {codigo: 95 , text: 'Pendiente Reenvio'},
      {codigo: 94 , text: 'Esperando Respuesta'},
      {codigo: 90 , text: 'Firmado Pendiente'},
      {codigo: 103 , text: 'Error en Hacienda'},
      {codigo: 104 , text: 'Procesando en Hacienda'},
      {codigo: 105 , text: 'Procesando en Hacienda'},
      {codigo: 200 , text: 'Hacienda: Internal Server Error'},
      {codigo: 201 , text: 'Hacienda: Bad Gateway'},
      {codigo: 500 , text: 'Hacienda: Internal Server Error'},
      {codigo: 1400 , text: 'Hacienda: '},
      {codigo: 1500 , text: 'Hacienda: Internal Server Error'},
      {codigo: 1502 , text: 'Hacienda: Bad Gateway'},
      {codigo: 600 , text: 'Sin respuesta'},
      {codigo: 1 , text: 'Aceptado Hacienda'},
      {codigo: 3 , text: 'Rechazado Hacienda'},
    ],
    estadoDocumentoSelect: [
      {codigo: 0 , text: 'Todos'},
      {codigo: 1 , text: 'Aprobado'},
      {codigo: 3 , text: 'Rechazado'},
    ],
    tipoDocumento: [
      {codigo: '00' , text: 'Todos'},
      {codigo: '01' , text: 'Factura electrónica'},
      {codigo: '02' , text: 'Nota de débito electrónica'},
      {codigo: '03' , text: 'Nota de crédito electrónica'},
      {codigo: '04' , text: 'Tiquete Electrónico'},
      {codigo: '08' , text: 'Factura Compra'},
    ],
    tipoIdDocumento:[
      {codigo: '01' , text: 'Cédula Física'},
      {codigo: '02' , text: 'Cédula Jurídica'},
      {codigo: '03' , text: 'DIMEX'},
      {codigo: '04' , text: 'NITE'},
    ],
    condicionesVenta:[
      {codigo: '01' , text: 'Contado'},
      {codigo: '02' , text: 'Crédito'},
      {codigo: '03' , text: 'Consignación'},
      {codigo: '04' , text: 'Apartado'},
      {codigo: '05' , text: 'Arrendamiento con opción de compra'},
      {codigo: '06' , text: 'Arrendamiento en función financiera'},
      {codigo: '07' , text: 'Cobro a favor de un tercero'},
      {codigo: '08' , text: 'Servicios prestados al Estado a crédito'},
      {codigo: '09' , text: 'Pago del servicios prestado al Estado'},
    ],
    medioPago:[
      {codigo: '01' , text: 'Efectivo'},
      {codigo: '02' , text: 'Tarjeta'},
      {codigo: '03' , text: 'Cheque'},
      {codigo: '04' , text: 'Transferencia-depósito bancario'},
      {codigo: '05' , text: 'Recaudado por terceros'},

    ],

    accionCodRef:[
      {codigo: '01', text: 'Anula Documento de Referencia'},
      {codigo: '02', text: 'Corregir monto'},
      {codigo: '04', text: 'Referencia a otro documento'},
      {codigo: '05', text: 'Sustituye comprobando provisional por contingencia'},
      {codigo: '99', text: 'Otros'},
    ],
    razonRef:[
      {text: 'Nota de despacho'},
      {text: 'Contrato'},
      {text: 'Procedimiento'},
      {text: 'Comprobante emitido en contingencia'},
      {text: 'Devolucion mercaderia'},
      {text: 'Sustituye factura rechazada por el Ministerio de Hacienda'},
      {text: 'Sustituye factura rechazada por el Receptor del comprobante'},
      {text: 'Sustituye factura de exportacion'},
      {text: 'Facturacion mes vencido'},
      {text: 'Otros'},
    ],
    otrosCargos:[
      {codigo:'01',	text: 'Contribución parafiscal'},
      {codigo:'02',	text: 'Timbre de la Cruz Roja'},
      {codigo:'03',	text: 'Timbre de Benemérito Cuerpo de Bomberos de Costa Rica.'},
      {codigo:'04',	text: 'Cobro de un tercero'},
      {codigo:'05',	text: 'Costos de Exportación'},
      {codigo:'06',	text: 'Impuesto de servicio 10%'},
      {codigo:'07',	text: 'Timbre de Colegios Profesionales'},
      {codigo:'99',	text: 'Otros Cargos'},
    ],
    clienteSearch: '',
    //menu facturacion
    facturaViewListState: false,
    addFactura: false,
    itemsFacturaBorrador: [],
    catMonedas: [],
    addActivity: false,
    listActivities: false,
    defaultActivity: '',

    //menu Tiquetes
    facturaTiqueteListState: false,
    addFacturaTiquete: false,
    itemsTiqueteBorrador: [],

    //menu Notas
    facturaNotaListState: false,
    addFacturaNota: false,
    itemsNotaBorrador: [],
    
    //menu Compras
    facturaCompraListState: false,
    addFacturaCompra: false,
    itemsCompraBorrador: [],

    //menu proformas
    facturaProformaListState: false,
    addFacturaProforma: false,
    itemsProformaBorrador: [],

    //menu contingencia
    facturaContigenciaListState: false,
    addFacturaContigencia: false,
    itemsContigenciaBorrador: [],


    actividadByUser: [],
    itemsActivities: [],
    itemsDirections: [],

    addCliente: false,
    listClientes: false,
    itemsListClientes: [],

    addVendedor: false,
    listVendedores: false,
    itemsListVendedores: [],

    addArticulo: false,
    listArticulos: false,
    itemsListArticulos: [],

    catUnidades: [],
    catImpuestos: [],
    catTarifas: [],
    catExoneracion: [],

    addCabys: false,
    itemsCabys: [],


    //menu dialogo opciones listado general
    openDialogOption: false,
    openDialogRespuestaRechazo: false,

    //menu catalogs
    catalogActivity: false,
    catalogArticulos: false,
    catalogClientes: false,
    catalogVendedores: false,

    //Reportes
    reporteIva: false,
    reporteDocRangoFecha: false,
    reporteAuxiliarCompraVenta: false,
    reporteCompras: false,
    reporteComprasIva: false,
    reporteVentasxArt: false,

    //manual pdf
    downloadManualPDF: false,

    //version
    versionEfac: false,
    changePass: false,

    mensajeError: 'Predefinido',

    facturaCargaManual: false,
    facturaCargaAuto: false,

    itemsSucursales: []


  },
  getters: {
  },
  mutations: {
    MUT_RESET_STATE(state){
      state.facturaViewListState =  false
      state.addFactura =  false
      state.itemsFacturaBorrador =  []
      state.catMonedas =  []
      state.addActivity =  false
      state.listActivities =  false
      state.defaultActivity =  ''
      state.actividadByUser =  []
      state.itemsActivities =  []
      state.itemsDirections =  []
      state.addCliente =  false
      state.listClientes =  false
      state.itemsListClientes =  []
      state.addVendedor =  false
      state.listVendedores =  false
      state.itemsListVendedores =  []
      state.addArticulo =  false
      state.listArticulos =  false
      state.itemsListArticulos =  []
      state.catUnidades =  []
      state.catImpuestos =  []
      state.catTarifas =  []
      state.catExoneracion = []

      state.addCabys =  false
      state.itemsCabys =  []
    },
    MUT_ITENMS_WL(state, data){
      state[data.nombre] = data.data
    },
    MUT_CAT_MONEDAS(state, data){
      state.catMonedas = data
    },
    MUT_ACTIVIDAD_USER(state, data){
      state.actividadByUser = data
    },
    MUT_DEFAULT_ACTI(state, data){
      if (data){
        const defaultAct = data.find(def=> def.activo == 1)
        state.defaultActivity = defaultAct ? `${defaultAct.actividad}-${defaultAct.descripcion}` : ''
      }
    },
    MUT_LISTCLIENTE(state, data){
      state.listClientes = data
    }
  },
  actions: {
    actListClientes({commit}, data){
      commit('MUT_LISTCLIENTE', data)
    },
    actResetState({commit}){
      commit('MUT_RESET_STATE')
    },
    fechData(_, obj){
      return new Promise((resolve, reject) =>{
        axiosbd({
          method: obj.method,
          url: obj.url,
          data: obj.body ? obj.body : {},
          headers: {
              Authorization: `Bearer ${obj.token}`,
          },
        })
        .then((res) => {
          // console.log(res.data)
          resolve(res.data)
        })
        .catch((err) => {
          reject(err)
        })
      })
    },
    fechData2(_, obj) {
      return new Promise((resolve, reject) => {
        axiosbd({
              method: obj.method,
              url: obj.url,
              data: obj.body ? obj.body : {},
              headers: {
                  Authorization: `Bearer ${obj.token}`,
              },
              params: obj.params ? obj.params : null
          })
              .then((res) => {
                  resolve(res.data)
              })
              .catch((err) => {
                  reject(err)
              })
      })
  },
    actItemsBaseWindowsList({commit}, data){
      // console.log(data)
      commit('MUT_ITENMS_WL', data)
    },
    actCatMonedas({commit}, data){
      commit('MUT_CAT_MONEDAS', data)
    },
    actDefaultActivity({commit}, data){
      commit('MUT_DEFAULT_ACTI', data)
    },
  },
  modules: {
    AuthModule
  }
})
